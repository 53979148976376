import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'
import { PointsMaterial } from 'three'

// Texture Loader
const loader = new THREE.TextureLoader()
const star = loader.load('./star.png')

// Debug
//const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Objects
const geometry = new THREE.TorusGeometry( 1.2, .5, 9, 81 )
const cylinder = new THREE.CylinderGeometry(.5,.5,.2,42,4,true,2.2,5)

// Particles
const particleGeometry = new THREE.BufferGeometry()
const particlesCount = 10000
const posArray = new Float32Array(particlesCount * 3)

for (let i = 0; i < particlesCount * 3; i++)
{
    posArray[i] = (Math.random() - 0.5) * (Math.random()*5);
}

particleGeometry.setAttribute('position', new THREE.BufferAttribute(posArray, 3))


// Materials
const material = new THREE.PointsMaterial({
    size: 0.01,
    color: 'red'
    
}
)

const materialLogo = new THREE.PointsMaterial({
    size: 0.01,
    color: 'cyan'
}
)

const particlesMaterial = new THREE.PointsMaterial({
    size: 0.0032,
    map: star,
    transparent: true,
    color: 'cyan',
    //blending: THREE.AdditiveBlending
}
)


// Mesh
const sphere = new THREE.Points(geometry,material)
const particlesMesh = new THREE.Points(particleGeometry, particlesMaterial)
const logo = new THREE.Points(cylinder,materialLogo)
scene.add(sphere, particlesMesh, logo)


// Lights

const pointLight = new THREE.PointLight(0xffffff, 0.1)
pointLight.position.x = 2
pointLight.position.y = 3
pointLight.position.z = 4
scene.add(pointLight)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 0
camera.position.y = 0
camera.position.z = 2
scene.add(camera)

// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.setClearColor(new THREE.Color('#111122'),1)


// Mouse

document.addEventListener('mousemove', animatedParticles)

let mouseX = 0
let mouseY = 0

function animatedParticles(event)
{
    mouseX = event.clientX
    mouseY = event.clientY
}


/**
 * Animate
 */

const clock = new THREE.Clock()

const tick = () =>
{

    const elapsedTime = clock.getElapsedTime()

    // Update objects
    sphere.rotation.x = .3 * elapsedTime
    sphere.rotation.z = .3 * elapsedTime
    particlesMesh.rotation.x = -.05 * elapsedTime
    logo.rotation.x = (.3 * elapsedTime) + 1.5

    if (mouseX > 0)
    {
     particlesMesh.rotation.y = -mouseX * (elapsedTime * 0.00001)
    }

    // Update Orbital Controls
    // controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()